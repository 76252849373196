<template>
	<div id="app" v-loading.fullscreen.lock="loading" element-loading-background="rgba(0,0,0,0.4)">
		

		<router-view />

		<div id="footer" ref="footer" v-if="show_footer">
			<nav>
				<div class="nav_item">
					<router-link to="/" v-if="router_name=='home'">
						<img class="rl_active" src="./assets/home_active.png" alt="">
						<div class="c_blue2">{{$t('首页')}}</div>
					</router-link>
					<router-link to="/" v-if="router_name!='home'">
						<img class="rl_normal" src="./assets/home.png" alt="">
						<div>{{$t('首页')}}</div>
					</router-link>
				</div>
				
				<div class="nav_item">
					<router-link to="/market" v-if="router_name=='market'">
						<img class="rl_active" src="./assets/market_active.png" alt="">
						<div class="c_blue2">{{$t('市场')}}</div>
					</router-link>
					<router-link to="/market" v-if="router_name!='market'">
						<img class="rl_normal" src="./assets/market.png" alt="">
						<div>{{$t('行情')}}</div>
					</router-link>
				</div>
				
				<div class="nav_item">
					<router-link to="/trade/12" v-if="router_name=='trade'">
						<img class="rl_active" src="./assets/trade_active.png" alt="">
						<div class="c_blue2">{{$t('交易')}}</div>
					</router-link>
					<router-link to="/trade/12" v-if="router_name!='trade'">
						<img class="rl_normal" src="./assets/trade.png" alt="">
						<div>{{$t('交易')}}</div>
					</router-link>
				</div>
				
				
				<div class="nav_item">
					<router-link to="/contract/12" v-if="router_name=='contract'">
						<img class="rl_active" src="./assets/contract_active.png" alt="">
						<div class="c_blue2">{{$t('合约')}}</div>
					</router-link>
					<router-link to="/contract/12" v-if="router_name!='contract'">
						<img class="rl_normal" src="./assets/contract.png" alt="">
						<div>{{$t('合约')}}</div>
					</router-link>
				</div>
				
				<div class="nav_item">
					<router-link to="/asset" v-if="router_name=='asset'">
						<img class="rl_active" src="./assets/asset_active.png" alt="">
						<div class="c_blue2">{{$t('资产')}}</div>
					</router-link>
					<router-link to="/asset" v-if="router_name!='asset'">
						<img class="rl_normal" src="./assets/asset.png" alt="">
						<div>{{$t('资产')}}</div>
					</router-link>
				</div>
				
			</nav>
		</div>
	</div>
</template>

<script>
import 'font-awesome/css/font-awesome.css'
export default {
	data(){
		return {
			show_footer: true,
			router_name: "home",
			loading: false,
		}
	},
	computed: {
		
		path: function(){
			console.log(this.$router.currentRoute.path);
			let t = this.$router.currentRoute.path.split('/')
			console.log(t);
			return t[1];
		}
	},
	created() {
		this.$router.beforeEach((to, from, next)=>{
			this.router_name = to.name;
			next();
		});
		this.router_name = this.$router.currentRoute.name;


		// interval check online
		// 30s
		let that = this;
		setInterval(function(){
			if( that.glogin() ){
				that.greq('post', '/api/hb', {}, function(){
					// ...
				});
			}
		}, 10000);
	}
}
</script>

<style>

</style>
