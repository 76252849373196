import Vue from 'vue';
import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';

import './element-variables.scss'
import App from './App.vue';
import router from './router'
import VueI18n from 'vue-i18n';
import axios from 'axios'
import VueCookie from 'vue-cookie'


Vue.prototype.$axios = axios

// import 'element-ui/lib/theme-chalk/base.css';
// collapse 展开折叠
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';
Vue.component(CollapseTransition.name, CollapseTransition)

// config
// Vue.prototype.GAPI = "http://jysapi.local.com/"
Vue.prototype.GAPI = "https://api.coexezry.com/"

import './css/main.css';


Vue.use(ElementUI);
Vue.use(VueI18n);
Vue.use(VueCookie)


import DrawerLayout from 'vue-drawer-layout'

Vue.use(DrawerLayout)

// language
const default_lang = 'en';
const i18n = new VueI18n({
	locale: localStorage.getItem('lang') || default_lang,
	messages: {
		en: require('./language/en.json'),
		tw: require('./language/tw.json'),
		th: require('./language/th.json'),
		fr: require('./language/fr.json'),
		de: require('./language/de.json'),
		jp: require('./language/jp.json'),
		ko: require('./language/ko.json'),
		es: require('./language/es.json'),
		vi: require('./language/vi.json'),
	}
})

// common funcs
// --- get current language
Vue.prototype.glang = function(){
	return localStorage.getItem('lang') || default_lang;
}
Vue.prototype.greq = function(type, url, data, success, fail){
	type = type.toUpperCase()
	let option = {
		method: type,
		url: this.GAPI+url,
		validateStatus: function (status) {
			return status >= 200 && status < 300; // default
		},
	}
	if(typeof(data) != 'undefined' && data) {
		if( type == 'GET' ){
			option.param = data;
		} else {
			option.data = data;
		}
	}
	
	// 携带登录头
	if( localStorage.getItem('token') ){
		option.headers = {
			'UserToken': localStorage.getItem('token'),
			'UserLang': localStorage.getItem('lang') || default_lang
		}
	} else {
		option.headers = {
			'UserLang': localStorage.getItem('lang') || default_lang
		}
	}
	let that = this;
	this.$axios(option).then(function(rs){
		if(rs.status == 204){
			// token error, reset
			localStorage.removeItem('token')
			// goto login page
			that.$router.push('/login')
			return
		}
		
		success(rs.data)
	}).catch(function(err){
		console.log(err)
		if(typeof(fail) != 'undefined'){
			fail(err)
		}
		// 	that.$message({message:'Network error',type:'error'})
		// }
	})
}
Vue.prototype.glogin = function(){
	return localStorage.getItem('token') != null
}

Vue.prototype.gerror = function(msg){
	this.$message({message:msg, type:'error'})
}


new Vue({
  el: '#app',
  i18n,
  router,
  render: h => h(App)
});

