<template>
	<div class="recharge_page bg_white">
		<div class="" style="border-bottom:1px solid #eee;">
			<el-row type="flex" align="middle" class="p3">
				<el-col :span="6" class="tl f16">
					<div @click="$router.go(-1)" class="c_black2">
						<i class="fa fa-angle-left f18"></i>
					</div>
				</el-col>
				<el-col :span=12 class="f16">
					<span v-if="show_result==false">{{$t('币种')}}</span>
					<span v-if="show_result==true">{{$t('充值')}}</span>
				</el-col>
				<el-col :span=6 class="tr">
					<span></span>
				</el-col>
			</el-row>
		</div>

		<div class="p4 tl" v-if="show_result==false">
			<div v-if="addr!=''">
				<div style="width: 150px;margin:0 auto;">
					<qrcode-vue :value="addr" :size="150" level="H" />
				</div>
				<div class="tc f12 mt2 c_gray">{{addr}}</div>
				<div class="tc mt2">
					<el-button round size="mini" @click="doCopy">{{$t('点击复制充值地址')}}</el-button>
				</div>
			</div>

			<div class="mt4">{{$t('充币')}}</div>
			<div class="mt3">
				<el-input :placeholder="$t('请输入数量')" v-model="amount" class="input-with-select" size="small">
					<el-select v-model="type" slot="prepend" :placeholder="$t('请选择')" size="small">
						<el-option :label="$t('选择币种')" value=""></el-option>
						<el-option label="USDT-ERC20" value="0"></el-option>
						<el-option label="USDT-TRC20" value="1"></el-option>
						<el-option label="BTC" value="2"></el-option>
						<el-option label="ETH" value="3"></el-option>
					</el-select>
				</el-input>
			</div>

			<div class="mt4">{{$t('上传图片')}}</div>
			<div>
				<div style="width:100px;height:100px;display: flex;flex-direction:column;justify-content:center" class="bg_gray br6 mt3 tc f18 c_black2" @click="callUpload">
					<div v-if="pic==''">
						<div>
							<i class="fa fa-plus"></i>
						</div>
						<div class="f12">{{$t('选择图片')}}</div>
					</div>
					
					<div v-if="pic!=''">
						<img :src="pic" style="width:100%" alt="">
					</div>
					<input type="file" ref="pic" style="display: none;" @change="uploadFile('pic')">
				</div>
			</div>

			<div class="mt5 tc">
				<el-button type="primary" round class="w80" @click="submit">{{$t('标记已支付')}}</el-button>
			</div>
		</div>
		
		<!-- 支付结果 -->
		<div class="p4" v-if="show_result">
			
		</div>
	</div>
</template>

<script>
	import QrcodeVue from 'qrcode.vue'
	import Vue from 'vue'
	import VueClipboard from 'vue-clipboard2'
	
	VueClipboard.config.autoSetContainer = true // add this line
	Vue.use(VueClipboard)
	
	
	export default {
		name: "RechargeView",
		components: {
			QrcodeVue,
		},
		props: ['default_type'],
		data() {
			return {
				type: "",
				amount: "",
				amount_min: 10,
				trc: "",
				erc: "",
				btc: "",
				eth: "",
				// addr: "",
				pic: "",
				pic_name: '',
				show_result: false, // 显示支付结果
				
			}
		},
		computed: {
			addr: function(){
				if( this.type === '' ){
					return '';
				}
				// return this.type == 0 ? this.erc :this.trc;
				let tmp = [this.erc, this.trc, this.btc, this.eth]
				return tmp[this.type]
			}
		},
		methods: {
			doCopy(){
				this.$copyText(this.addr);
				this.$message(this.$t('复制成功'));
			},
			
			callUpload() {
				this.$refs.pic.click();
			},
			
			// upload file
			uploadFile() {
				// console.log(this.$refs.cardzm.files[0])
				let that = this;
				let fname = this.$refs.pic.value;
				if (!fname.match(/\.(png|jpg|jpeg|gif)$/i)) {
					// layer.msg("{:L('Only support jpg/png')}");
					this.$message({
						message: this.$t("仅支持JPG/PNG格式文件"),
						type: 'error'
					})
					return false;
				}
			
				var fileObj = this.$refs.pic.files[0];
				var reader = new FileReader();
			
				reader.readAsArrayBuffer(fileObj);
				reader.onload = function(ev) {
					var blob = new Blob([ev.target['result']]);
					window['URL'] = window['URL'] || window['webkitURL'];
					var blobURL = window['URL'].createObjectURL(blob);
					var image = new Image();
					image.src = blobURL;
					image.onload = (e) => {
						console.log(e)
						var thumb = that.resizeMe(image, 800, 0); //将图片转换成了base64
						
						// show loading
						
						that.greq('post', '/api/stream_upload/type/payimg', thumb, function(rs){
							if(rs.status){
								that.pic = rs.data.src;
								that.pic_name = rs.data.fname;
							}
						});
					}
				}
			},
			
			// 压缩图片
			resizeMe(img, max_width, max_height) {
				var canvas = document.createElement('canvas');
				var width = img.width;
				var height = img.height;
			
				// 在这里图片是等比例缩放的，调用方法时填入图片允许的最大宽度或者是最大的高度
				//如果最大宽度为0 则按照最大高度固定，宽度自适应的方式来实现
				//如果是最大高度为0，则按照最大的宽度来实现
				if (max_width == 0) {
					if (height > max_height) {
						width = Math.round(width *= max_height / height);
						height = max_height;
					}
				}
				if (max_height == 0) {
					if (width > max_width) {
						height = Math.round(height *= max_width / width);
						width = max_width;
					}
				}
				canvas.width = width;
				canvas.height = height;
				var ctx = canvas.getContext("2d");
				canvas.width = width;
				canvas.height = height;
				ctx.drawImage(img, 0, 0, width, height);
				return canvas.toDataURL("image/jpeg", 0.5); //这里的0.7值的是图片的质量
			},
			
			getData(){
				let that = this;
				this.$parent.loading = true;
				this.greq('get', '/api/recharge_config', false, function(rs){
					that.$parent.loading = false;
					if( rs.status ){
						that.amount_min = rs.data.amount_min;
						that.trc = rs.data.trc;
						that.erc = rs.data.erc;
						that.btc = rs.data.btc;
						that.eth = rs.data.eth;
					}
				}, function(err){
					that.$parent.loading = false;
					console.log(err)
				})
			},
			
			submit(){
				// this.$parent.loading = true;
				if( this.type === '' ){
					this.gerror(this.$t('请选择币种'));
					return
				}
				
				console.log(this.type);
				if( this.type < 2 ){
					if( this.amount < this.amount_min ){
						this.gerror(this.$t('最低充值')+this.amount_min);
						return
					}
				}
				
				if( !this.pic_name ){
					this.gerror(this.$t('请上传支付截图'));
					return 
				}
				
				let that = this;
				that.$parent.loading = true;
				this.greq('post', '/api/recharge', {type:this.type,amount:this.amount,pic:this.pic_name}, function(rs){
					that.$parent.loading = false;
					if( rs.status ){
						// jump to recharge_status page
						that.$message(that.$t('标记成功，请等候处理'));
						setTimeout(function(){
							that.$router.push('/recharge_history');
						}, 1000);
						
					} else {
						that.gerror(rs.msg);
					}
				}, function(err){
					that.$parent.loading = false;
					console.log(err);
				})
			},
		},
		mounted(){
			this.getData();
		},
		beforeCreate(){
			if( !this.glogin() ){
				this.$router.push('/login');
			}
			return false;
		},
		created() {
			console.log(this.default_type);
			if( this.default_type != undefined ){
				this.type = this.default_type;
			}
		}
	}
</script>

<style>
	.recharge_page .el-select {
		width: 150px;
	}
</style>
